import * as React from "react"
import LinktoFormButton from "../components/LinktoFormButton"
export default class TextWithLogo extends React.Component {
  render() {
    let section
    if (!this.props.removeLink) {
      section = (
        <div>
          <span className='logo' />
          <h4 className={`c-2A2A2A ` + this.props.classTitle}>{this.props.title}</h4>
          <div>
            <h2 className='m-t-110 m-w-275'>{this.props.subTitle}</h2>
            <p className='body-text m-w-490 m-t-30'>{this.props.text}</p>
            <LinktoFormButton isLink={this.props.isLink} text="I'm Interested" className={`m-t-30 ` + this.props.classLink} to={this.props.to} />
          </div>
        </div>
      )
    } else {
      section = (
        <div>
          <h4 className={`c-2A2A2A ` + this.props.classTitle}>{this.props.title}</h4>
          <div>
            <h2 className='m-t-110 m-w-275'>{this.props.subTitle}</h2>
            <p className='body-text m-w-490 m-t-30'>{this.props.text}</p>
          </div>
        </div>
      )
    }
    return <div className='h-100-p flex al-end  flex-r'>{section}</div>
  }
}
