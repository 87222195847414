
import * as React from "react"
import SliderFooter from "../components/sliderFooter"
import LogoTwo4Seven from "../components/logoTwo4Seven"
import PageLinkFade from "../components/PageTransitionFade"
import gsap from 'gsap'

export default class Footer extends React.Component {
  componentDidMount() {

    document.querySelectorAll("a").forEach((link) => {



      if ((window.location.pathname === link.pathname) || (window.location.pathname === (link.pathname + "/"))) {

        if (link.querySelector(".foot")) {
          let tl = gsap.timeline()
          tl.to(link.querySelector(".foot"), { color: "rgba(0,0,0,1)", duration: 0 })
        }

      }
    })
  }


  render() {
    return (
      <div className="back-white">
        <div className="b-t-1-b r-c-l">
          <div className="flex flex-r flex-c-br back-white p-40 p-b-180 p-t-50-br">
            <div className="w-50-p m-b-120-br ">
              <LogoTwo4Seven />
            </div>
            <div className="w-50-p  flex flex-r">
              <div className="p-r-130">
                <PageLinkFade to="/">
                  <span className="button button-style foot grey hoverBlack" >Home </span>
                </PageLinkFade>
                <br />
                <PageLinkFade to="/thetrip">
                  <span className="button button-style  c-p foot grey hoverBlack" id="discoverFooter">Discover </span>
                </PageLinkFade>
                <br />
                <PageLinkFade to="/memberships">
                  <span className="button button-style foot grey hoverBlack" >Memberships </span>
                </PageLinkFade>
                <br />
                <PageLinkFade to="/events">
                  <span className="button button-style foot grey hoverBlack">  Corporate consultancy </span>
                </PageLinkFade>
                <br />
                <PageLinkFade to="/about">
                  <span className="button button-style foot grey hoverBlack"  >About </span>
                </PageLinkFade>
                <br />
                <PageLinkFade to="/contact">
                  <span className="button button-style foot grey hoverBlack">Contact </span>
                </PageLinkFade>

              </div>
              <div>
                <a target="_blank" rel="noreferrer" className="button  grey hoverBlack" href="https://www.instagram.com/two4sevenau/">Instagram </a>
                <br />
                <a target="_blank" rel="noreferrer" className="button grey hoverBlack" href="https://www.facebook.com/two4sevensau/">Facebook </a>
                <br />


                <a target="_blank" rel="noreferrer" className="button grey hoverBlack" href="https://au.linkedin.com/company/two4sevenau">Linkedin </a>
              </div>
            </div>
          </div>
        </div>
        <div className="back-white b-t-1-b">
          <SliderFooter />
        </div>

      </div>
    )
  }
}
