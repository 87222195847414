import * as React from "react"
import { StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Header from "../components/header"
import Seo from "../components/seo"
import SplitSection from "../components/SplitSection"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import TextWithLogo from "../components/TextWithLogo"
import TextWoLogo from "../components/TextWoLogo"
import Footer from "../components/footer"
import LinktoFormButton from "../components/LinktoFormButton"

export default class About extends React.Component {
  componentDidMount() {
    const body = document.querySelector("body")
    body.style.overflow = "unset"
  }
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            datoCmsAbout {
              aboutTitle
              aboutParagraph
              aboutImage {
                gatsbyImageData
              }
              aboutMemberOneName
              aboutMemberOneDesignation
              aboutMemberOneDescription
              aboutMemberTwoName
              aboutMemberTwoDesignation
              aboutMemberTwoDescription
              aboutBannerHeading
              useLinkInstead
              link
            }
          }
        `}
        render={({ datoCmsAbout }) => (
          <Layout>
            <Seo title='About' />
            <Header className='black w-b' isLink={datoCmsAbout.useLinkInstead} link={datoCmsAbout.link}/>
            <div data-scroll-container id='scroll-container' className='data-scroll-container'>
              <div className='h-50'></div>
              <div className='back-white '>
                <SplitSection
                  className=''
                  right={
                    <div data-scroll data-scroll-call='scale' className='h-100-p' data-scroll-offset='-100%'>
                      <GatsbyImage alt='' image={datoCmsAbout.aboutImage.gatsbyImageData} className='w-100-p h-100-p' layout='fullWidth' placeholder='dominantColor' />
                    </div>
                  }
                >
                  <TextWithLogo removeLink title={datoCmsAbout.aboutTitle} textClassName='alt' text={datoCmsAbout.aboutParagraph} className='p-t-140'></TextWithLogo>
                </SplitSection>

                <div className='b-t-1-b '>
                  <SplitSection
                    subWrapperClassName='b-b-1-br'
                    classRight='c-d-n-b '
                    wrapperClassName='stack'
                    right={
                      <div data-scroll data-scroll-call='fade-in' className='o-0-5 h-100-p w-100-p' data-scroll-offset='30%'>
                        <TextWoLogo isLink={datoCmsAbout.useLinkInstead} to={datoCmsAbout.link ?? '/contact' } classTitle='m-b-240-br' removeLink title={datoCmsAbout.aboutMemberTwoName} subTitle={datoCmsAbout.aboutMemberTwoDesignation} text={datoCmsAbout.aboutMemberTwoDescription} />
                      </div>
                    }
                  >
                    <div data-scroll data-scroll-call='fade-in' className=' o-0-5 h-100-p w-100-p' data-scroll-offset='30%'>
                      <TextWoLogo isLink={datoCmsAbout.useLinkInstead} to={datoCmsAbout.link ?? '/contact' } classTitle='m-b-240-br' classLink='m-b-0 display-none flex-d' title={datoCmsAbout.aboutMemberOneName} subTitle={datoCmsAbout.aboutMemberOneDesignation} text={datoCmsAbout.aboutMemberOneDescription} />
                    </div>
                    <div data-scroll data-scroll-call='scale' className='h-100-p w-100-p  display-none' data-scroll-offset='-100%'>
                      <StaticImage alt='' src='../images/Robbie.jpg' className='w-100-p h-100-p b-b-1-br' layout='fullWidth' placeholder='dominantColor' />
                    </div>
                  </SplitSection>
                </div>

                <div className='b-t-1-b display-none'>
                  <SplitSection
                    subWrapperClassName='flex-order-2-br '
                    classRight='flex-order-1-br b-b-1-br c-d-n-b'
                    wrapperClassName='stack'
                    className=''
                    right={
                      <div data-scroll data-scroll-call='scale' className='h-100-p' data-scroll-offset='-100%'>
                        <StaticImage alt='' src='../images/JAI.png' className='w-100-p h-100-p' layout='fullWidth' placeholder='dominantColor' />
                      </div>
                    }
                  >
                    <div data-scroll data-scroll-call='fade-in' className='o-0-5' data-scroll-offset='30%'>
                      <TextWoLogo isLink={datoCmsAbout.useLinkInstead} to={datoCmsAbout.link ?? '/contact' } classTitle='m-b-240-br' classLink='m-b-0' title={datoCmsAbout.aboutMemberOneName} subTitle={datoCmsAbout.aboutMemberOneDesignation} text={datoCmsAbout.aboutMemberOneDescription} />
                    </div>
                  </SplitSection>
                </div>
                <div className='p-t-120 p-b-80 p-t-60-br p-b-60-br  flex flex-center-h b-t-1-b flex-c '>
                  <div className='flex flex-center-h'>
                    <h1 className='heading-0'>{datoCmsAbout.aboutBannerHeading}</h1>
                  </div>
                  <div className='flex flex-center-h p-t-40'>
                    <LinktoFormButton isLink={datoCmsAbout.useLinkInstead} to={datoCmsAbout.link ?? '/contact' } text=' I’M INTERESTED ' className='m-b-0'  />
                  </div>
                </div>
                <Footer />
              </div>
            </div>
          </Layout>
        )}
      />
    )
  }
}
